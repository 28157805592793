import { render, staticRenderFns } from "./ladderWidget.vue?vue&type=template&id=bb36fb92&scoped=true&"
import script from "./ladderWidget.vue?vue&type=script&lang=js&"
export * from "./ladderWidget.vue?vue&type=script&lang=js&"
import style0 from "./ladderWidget.vue?vue&type=style&index=0&id=bb36fb92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb36fb92",
  null
  
)

export default component.exports